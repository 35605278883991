import React from "react";
import BaseLayout from "../components/baseLayout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HeroTitle from "../components/heroTitle";

export default function DashboardsPage() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/dashboards//" } }
        sort: { order: ASC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            frontmatter {
              name
              description
              order
              href
              img {
                childImageSharp {
                  gatsbyImageData(width: 300, height: 300)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BaseLayout>
      <HeroTitle
        title="Dashboards"
      />

      <div className="section container is-size-5 has-text-justified">
          <p>
              Our dashboards are designed to provide a quick and easy way to access and visualize data.
          </p>
          <br/>

        <p>
            From weather forecast to satellite rainfall precipitation estimates, and geolocalized data
            from our <a href="https://3map.io" target="_blank" rel="noreferrer">3Map IMS</a>, our dashboards transform complex data into easily understandable and
            intuitive visualizations, empowering decision-makers and the public alike with actionable insights.
        </p>
      </div>

      <div className="section container">
        <div className="columns is-multiline is-centered is-mobile">
          {data.allMarkdownRemark.edges.map(({ node }, index) => (
            <Dashboard node={node} key={index} />
          ))}
        </div>
      </div>
    </BaseLayout>
  );
}

function Dashboard({ node }) {
  const name = node.frontmatter.name;
  const description = node.frontmatter.description;
  const href = node.frontmatter.href;
  const gatsbyImageData = node.frontmatter.img.childImageSharp.gatsbyImageData;

  return (
    <a
      className="shadow round responsiveMargin fixedWidth has-text-centered"
      href={href} target={"_blank"} rel={"noreferrer"}
    >
      <GatsbyImage image={gatsbyImageData} alt={name} />
      <div className="margin">
        <div className="title">{name}</div>
        <div className="subtitle">{description}</div>
      </div>
    </a>
  );
}
